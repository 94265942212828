import {
  CommandLineIcon,
  UserPlusIcon,
  BriefcaseIcon,
} from "@heroicons/react/20/solid";
import { motion } from "framer-motion";

import imgFeature from "../assets/feature-talent.jpg";

const features = [
  {
    name: "Driven by Your Needs",
    description:
      "We prioritize understanding your aspirations and matching you with the perfect opportunities, whether short-term projects or long-term engagements, remote or on-site.",
    icon: BriefcaseIcon,
  },
  {
    name: "Empowering Technology",
    description:
      "We're not just about tech; we're about the people behind it. Let us empower your career journey with exciting opportunities that align with your skills and ambitions.",
    icon: CommandLineIcon,
  },
  {
    name: "Personalized Support",
    description:
      "Your success is our priority. As real people with real interests, we tailor IT solutions to fit your unique career goals and aspirations. Let's make your dreams a reality together.",
    icon: UserPlusIcon,
  },
];

export default function Feacture() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-xl font-black leading-7" style={{ color: "#635BB8" }}>
              A bit About Us
              </h2>
              <p className="mt-2 text-5xl font-black tracking-tight sm:text-4xl" style={{ color: "#635BB8" }}>
              Connecting IT Talent with Global Opportunities.
              </p>
              <p className="mt-6 text-xl font-bold leading-8 text-gray-600">
              In the ever-expanding tech world, talent knows no boundaries. If you're an IT professional seeking international roles, you're in the right place.
              </p>
              <p className="mt-6 text-xl font-bold sleading-8 text-gray-600">
              We're a dedicated team of IT HR experts with over two decades of experience. Our mission? Bridging the gap between top-tier tech talent and leading companies worldwide.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline text-lg font-bold text-gray-900">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5"
                        aria-hidden="true"
                        style={{ color:"#635BB8" }}
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline text-lg font-semibold">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={imgFeature}
            alt="U&B STAFF"
            className="rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0 h-full object-cover"
            // width={2432}
            // height={1442}
          />
        </div>
      </div>
    </div>
  );
}
