// Terms.js
import React from 'react';

const Terms = () => {
  return (
    <div className="container mx-auto mt-8 text-gray-900 md:px-44 px-8 py-5">
      <h1 className="text-2xl font-black mb-4">USER AGREEMENT</h1>
      
      <p className="text-base font-semibold mb-4">U&B Staff is a talent solution firm. Our mission is to connect top talents from Latin America with firms in the U.S.A.
<br /><br />
We ensure the same time zone, utilize the same software, and facilitate direct communication between firms and professionals. We assist our clients in growing and scaling their businesses, building stronger, more efficient teams, and increasing their revenue.
<br /><br />
These Terms and Conditions ("Terms") apply to ubstaff.us and any other U&B Staff website on or accessible via ubstaff.us. This is the official U&B Staff website, and the company will not be responsible for any data entry made under a different domain name.
<br /><br />
By accessing and using this site, you accept the following terms and conditions, without limitation or qualification.
<br /><br />
Unless otherwise stated, the contents of this site, including but not limited to the text and images contained herein and their arrangement, are the property of U&B Staff.
<br /><br />
This website provides information about U&B Staff, including thought leadership and the services we provide. In these Terms, all references to website addresses or URLs will also include any successor or replacement websites containing substantially similar information as the referenced website.
<br /><br />
Nothing contained on this site shall be construed as conferring, by implication, estoppel, or otherwise, any license or right to any copyright, patent, trademark, or other proprietary interest of U&B Staff or any third party. This site and the content provided on this site, including but not limited to graphic images, audio, video, HTML code, buttons, and text, may not be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way without the prior written consent of U&B Staff.
<br /><br />
U&B Staff maintains this site in Florida, U.S.A, and you agree that these terms of use and any legal action or proceeding relating to this site shall be governed by the laws of the State of Florida without reference to its choice of law rules. If you attempt to bring any legal proceedings against U&B Staff, you specifically acknowledge that U&B Staff is free to choose the jurisdiction of our preference as to where such action against us may be held. As you have agreed by using this site to choose the laws of the State of Florida to govern any such proceedings, we will probably choose to defend any such action in Florida, and we can make this decision entirely as it suits us, without regard to where in the world you are located or from where in the world you visited this site.
<br /><br />
You are responsible for complying with the laws of the jurisdiction from which you are accessing this site, and you agree that you will not access or use the information on this site in violation of such laws. Unless expressly stated otherwise herein, any information submitted by you through this site shall be deemed non-confidential and non-proprietary. You represent that you have the lawful right to submit such information and agree that you will not submit any information unless you are legally entitled to do so.
</p>
    </div>
  );
};

export default Terms;
