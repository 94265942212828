// aboutus.js
import React from "react";
import Aplicationformnow from "../components/Aplicationform-now";
import Banner from "../components/Banner-about";
import { Container } from "@mui/material";

const Applynow = () => {
  return (
    <div>
      <Banner />
      <Container>
        <section>
          <Aplicationformnow />
        </section>
      </Container>
      <Container>
        <section className="pt-10">
        </section>
      </Container>
    </div>
  );
};

export default Applynow;
