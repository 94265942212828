// import heroImage from '../assets/HomeBanner.svg'
import heroImage from '../assets/Banner-talent.svg'
import { motion } from "framer-motion"
import Lottie from "react-lottie";
// import animationData from "../assets/world.json";
import '../App.css'

const links = [
    { name: 'Apply Now', href: './applynow', classNames:'rounded-full px-6 md:pr-6 pr-30 py-2 text-xl font-bold pill-hero-one ' },
    { name: 'Job Seekers', href: './jobseeker', classNames:'rounded-full px-6 md:pr-6 pr-30 py-2 text-xl font-bold pill-hero-two' },
  ]
  const stats = [
    { name: 'Success rate makes us the number one choice for IT talent acquisition.', value: '95%', classNames:'flex flex-col-reverse bg-white p-4 rounded-lg justify-end card-hero' },
    { name: 'IT talents linked with thriving startups globally, ready to drive innovation.', value: '500+', classNames:'flex flex-col-reverse bg-white p-4 rounded-lg justify-end card-hero' },
    { name: 'Candidates trust us to find quality job opportunities throughout LATAM', value: '12K+', classNames:'flex flex-col-reverse bg-white p-4 rounded-lg justify-end card-hero' },
    { name: 'Recruiters specialized in IT Talent Acquisition across LATAM.', value: '20+', classNames:'flex flex-col-reverse bg-white p-4 rounded-lg justify-end card-hero' },
  ]

  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  export default function Header() {
    return (
      <div className="relative isolate overflow-hidden py-24 sm:py-32">
        <img
          src={heroImage}
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover md:object-center"
        />
         {/* <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="overflow-hidden absolute w-[140px] md:left-[33rem] md:top-[4rem] left-[12rem] top-[1rem]"
            style={{  }}
          >
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
              }}
            />
          </motion.div> */}
          
        {/* <div
          className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div> */}
        {/* <div
          className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div> */}
           
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            {/* <h2 style={{ lineHeight:'4rem' }} className="text-7xl font-black tracking-tight text-gray-900 "> */}
            <motion.h2
            style={{ lineHeight: '4rem', }}
            className="text-7xl font-black tracking-tight text-white"
            variants={fadeInUpVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5, delay: 0.1 }} // Ajusta el delay según tus preferencias
          >
              Unlock Your Potential 
              <br />
              with U&B Staff
              </motion.h2>
              <motion.p
            style={{ lineHeight: '1.5rem' }}
            className="mt-6 text-xl font-bold text-white"
            variants={fadeInUpVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5, delay: 0.1 }} // Ajusta el delay según tus preferencias
          >
            Ready to elevate your IT career internationally? Look no further. 
            <br /> 
            At U&B Staff, we provide custom HR solutions to kickstart your global career journey.
            </motion.p>
          </div>
          <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-xl font-bold leading-7 sm:grid-cols-2 md:flex lg:gap-x-10">
              {links.map((link, index) => (
                 <motion.div
                 key={link.name}
                 className={`${index + 1} ${link.classNames}`}
                 variants={fadeInUpVariants}
                 initial="hidden"
                 animate="visible"
                 transition={{ duration: 0.8, delay: index * 0.2 }}
               >
                <a 
                key={link.name} 
                href={link.href}
                className={'md:pr-0 pr-40'}
                style={{ backgroundColor:'' }}
                >
                  {link.name} 
                  {/* <span aria-hidden="true">&rarr;</span> */}
                
                </a>
                </motion.div>
              ))}
            </div>
            <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat, index) => (
              <motion.div
                key={stat.name}
                className={`${index + 1} ${stat.classNames}`}
                
                initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: index * 0.2,
        ease: [0, 0.71, 0.2, 1.01]}}
              >
                  <dt className="text-xl leading-7 text-blue-950 font-bold">{stat.name}</dt>
                  <dd className="text-3xl font-black leading-9 tracking-tight text-blue-950">{stat.value}</dd>
              </motion.div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    )
  }
  