import React from 'react';
import { motion } from 'framer-motion';

import img01 from '../assets/01.svg';
import img02 from '../assets/02.svg';
import img03 from '../assets/03.svg';
import img04 from '../assets/04.svg';
import img05 from '../assets/05.svg';

const steps = [
  { title: 'Explore & Apply', text: 'Review our job postings', src: img01 },
  { title: "General Interview", text: "We'll schedule 30-min meeting.", src: img02 },
  { title: 'Gear Up', text: 'We’ll ask you to complete your portfolio.', src: img03 },
  { title: 'Client Interview', text: 'It’s time to showcase your skills!', src: img04 },
  { title: 'Kick-off Meeting', text: 'Congrats on your new job!', src: img05 },
];

const HowToApply = () => {
  return (
    <div className='px-10'>
      <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: 'easeInOut' }}
      className="">
      <h1 className="font-black text-4xl text-center" style={{ color:'#301C49' }}>Boost your career | In 5 simple steps</h1>
      <p className="text-xl font-semibold text-center pt-5" style={{ color:'#301C49' }}>
      We are an outsourcing company that connects US-based clients with Latin America’s top 3% talent.
      </p>
    </motion.div>
      <dl className=" grid grid-cols-1 gap-8 sm:mt-10 mt-10 sm:grid-cols-2 lg:grid-cols-4">
        {steps.map((step, index) => (
          <motion.div
            key={step.title}
            className="flex items-center space-x-5"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: index * 0.2,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <img src={step.src} alt={`Step ${index + 1}`} className="w-16 h-16" />
            <div>
              <h2 className="text-xl leading-7 text-blue-950 font-bold">{step.title}</h2>
              <p className="text-base font-bold text-gray-900">{step.text}</p>
            </div>
          </motion.div>
        ))}
      </dl>
    </div>
  );
};

export default HowToApply;
