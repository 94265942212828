import React from 'react';
import { motion } from 'framer-motion';

import img404 from '../assets/404.svg'

const NotFoundPage = () => {
  return (
    <div className="flex items-center text-center flex-col justify-center h-screen bg-gray-100">
        <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.5 }}
        transition={{ duration: 0.5 }}
        >
        <img src={img404} alt="" className='w-72'/>
        </motion.div>

      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.5 }}
        transition={{ duration: 0.5 }}
        className="bg-white p-8 rounded-lg flex items-center justify-center flex-col"
      >
        <h1 className="text-5xl font-black text-gray-800 mb-4">404 - Not Found</h1>
        <p className="text-2xl text-gray-600 font-bold mb-8">Oops! The page you are looking for might be under construction or does not exist.</p>
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="text-gray-900 font-bold cursor-pointer bg-green-200 p-2 px-5 rounded-lg"
          onClick={() => window.history.back()}
        >
          Go back
        </motion.div>
      </motion.div>
    </div>
  );
};

export default NotFoundPage;
