import React from 'react';
import { motion } from 'framer-motion';

const Text = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: 'easeInOut' }}
      className="px-10 pb-10">
      <h1 className="font-black text-5xl mb-4 text-center" style={{ color:"#635BB8" }}>Unlock Your Potential with Us</h1>
      <p className="text-xl font-semibold text-gray-600 mb-2 text-center">
      Ready to advance your career in the thriving IT industry? Join us and explore exclusive opportunities tailored to your ambitions. Our dedicated team is committed to providing the support and resources necessary for your professional growth.
      </p>
      <p className="text-xl font-semibold text-gray-600 text-center">
      With our streamlined recruitment process, we ensure a perfect fit for you every time. From identifying ideal roles to offering expert advice, we're here to guide you at every step of your journey to success. Let's embark on this exciting adventure together and unlock your potential.
      </p>
    </motion.div>
  );
};

export default Text;
