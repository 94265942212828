// contact.js
import React from 'react';
import Contact from '../components/Contact'
import { Container } from '@mui/material';
import Banneranimation from '../components/Bannercontact';


const contact = () => {
  return (
    <div>
    <Banneranimation />
    <Container>
      <section>
        <Contact/>
      </section>
    </Container>
    </div>    
  );
};

export default contact;
