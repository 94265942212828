import CountUp from 'react-countup';

const stats = [
  { id: 1, name: 'Hired Resources', value: 2320, text: 'Millon' },
  { id: 2, name: 'Satisfied Companies', value: 52, text: 'Trillon' },
  { id: 3, name: 'Open Jobs', value: 120, text: 'K' },
];

export default function Stats() {
  return (
    <div className="py-20 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          {stats.map((stat) => (
            <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
              <dt className="text-3xl leading-7 font-bold" style={{ color:"#3A337F" }}>{stat.name}</dt>
              <CountUp
                end={stat.value} 
                duration={1} // ajusta la duración según tus preferencias
                className="order-first text-5xl font-black tracking-tight text-blue-900 sm:text-8xl"
                style={{ color:"#3A337F" }}
              />
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
