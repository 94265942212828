import * as React from 'react';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { motion } from "framer-motion"
import Lottie from "react-lottie";

import streamlined from "../assets/streamlined.json";
import jorney from "../assets/jorney.json";
import future from "../assets/future.json";

import img1 from '../assets/card1.jpg';
import img2 from '../assets/card2.jpg';
import img3 from '../assets/card3.jpg';

const CustomCard = ({ title, description, animationData }) => {
  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      duration: 0.8,
      delay: 0.5,
      ease: [0, 0.71, 0.2, 1.01]}}
    >
    <Card sx={{ maxWidth: 500, borderRadius:'15px', minHeight:'30rem' }} className="m-4 rounded-3xl">
      {/* <CardMedia
        component="img"
        alt="Card Image"
        height="140"
        width="300"
        image={imageUrl}
      /> */}
      <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
          }}
          // height={140}
          width={200}
        />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" style={{ color: '#111827',fontWeight:'bold' }}>
          {title}
        </Typography>
        <Typography variant="" color="" className='text-lg font-semibold text-gray-600 ' style={{ color: '#111827' }}>
          {description}
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
    </motion.div>
  );
};

const ImgMediaCard = () => {
  return (
    <div className="flex sm:flex-row flex-col xs:flex-col items-center">
      <CustomCard
        title="Streamlined Recruitment"
        description="With our streamlined recruitment process, we ensure that we understand your needs and find the perfect fit for you. From identifying opportunities to providing expert advice, we're here to support you every step of the way."
        // imageUrl={img1}
        animationData={streamlined}
      />
      <CustomCard
        title="Your Career Journey"
        description="Partnering with one of our Business Development Managers means gaining a dedicated consultant who will take the time to understand your goals, values, and aspirations. Together, we'll design tailored solutions to propel your career forward."
        // imageUrl={img2}
        animationData={jorney}
      />
      <CustomCard
        title="Let's Build Your Future"
        description="We offer a comprehensive range of professional services aimed at fueling your professional growth. Whether it's a simple placement or a more complex career move, we're committed to your success. Let's embark on this journey together and unlock your full potential."
        // imageUrl={img3}
        animationData={future}
      />
    </div>
  );
};

export default ImgMediaCard;
