import React from 'react';
import './App.css';
import Topbar from './conteiners/Topbar';
import Footer from './conteiners/Footer';
import Header from './components/Header';
import Feacture from './components/Feacture';
import Stats from './components/Stats';
import Cards from './components/Cards';
import Banneranimation from './components/Banneranimation';
import Text from './components/Text';
import Faq from './components/faq';
import Cta from './components/Cta';

import { Container } from '@mui/material';

// import img1 from './assets/ubbanneroscuro.svg'

function App() {
  return (
    <div className="App">
      <Header />
      <section>
        <Container maxWidth="lg">
          <Feacture />
        </Container>
      <Banneranimation />
      </section>
      {/* <section style={{ backgroundImage: `url(${img1})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'right' }}> */}
      <section style={{ backgroundColor: '#B2AFDC', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'right' }}>
        <Stats />
      </section>
      <section className='pt-20 pb-20 bg-white' style={{  backgroundColor: '',backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'right' }}>
        <Container maxWidth="lg">
          <Text />
          <Cards />
        </Container>
      </section>
      <section style={{ backgroundColor: '#D89FFC', paddingBottom:'2rem' }}>
        <Faq />
      </section>
      <section>
        <Container style={{  }} className='py-10'>
          <Cta />
        </Container>
      </section>
    </div>
  );
}

export default App;
