import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Grid, Button, Select, MenuItem, InputLabel } from '@mui/material';
import { Link } from 'react-router-dom'; // Importa Link de react-router-dom
import data from '../utils/busquedas.json';

const TarjetasComponent = () => {
  const [filtroFecha, setFiltroFecha] = useState('');
  const [filtroCategoria, setFiltroCategoria] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 8;

  const handleFiltroFechaChange = (event) => {
    setFiltroFecha(event.target.value);
    setFiltroCategoria(''); // Limpiar el filtro de categoría al cambiar la fecha
    setCurrentPage(1); // Reiniciar la página actual al cambiar los filtros
  };

  const handleFiltroCategoriaChange = (event) => {
    setFiltroCategoria(event.target.value);
    setFiltroFecha('');
    setCurrentPage(1);
  };
  
  // Ordenar los datos por ID de forma descendente
  const sortedData = [...data].sort((a, b) => b.id - a.id);

  const filteredData = sortedData.filter((item) => {
    const fechaMatch = filtroFecha === '' || item.fecha === filtroFecha;
    const categoriaMatch = filtroCategoria === '' || item.categoria.includes(filtroCategoria);
    return fechaMatch && categoriaMatch;
  });

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = filteredData.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className='py-20 align-middle'>
      <Grid container spacing={2} justifyContent="flex-end" className='py-5 align-middle' style={{ alignItems:'center' }}>
        <Grid item className='flex align-middle'>
          <InputLabel style={{ fontWeight:'800',paddingRight:'5px' }} className='px-5 text-gray-900'>Category</InputLabel>
          <Select value={filtroCategoria} onChange={handleFiltroCategoriaChange} style={{ backgroundColor:'#FDD46F',color:'#3A337F',padding:'10px', width:'auto', height:'25px' }}>
            <MenuItem value="">All Categories</MenuItem>
            <MenuItem value="Frontend">Frontend</MenuItem>
            <MenuItem value="Backend">Backend</MenuItem>
            <MenuItem value="Mobile">Mobile</MenuItem>
            <MenuItem value="Sales">Sales</MenuItem>
            <MenuItem value="Engineer">Engineer</MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => { setFiltroFecha(''); setFiltroCategoria(''); setCurrentPage(1); }}
          className='bg-green-200'
          style={{ backgroundColor:'#3A337F' }}
          >
            Empty filters
          </Button>
        </Grid>
      </Grid>
      {filtroCategoria && (
        <div style={{ textAlign: 'center', marginTop: '10px', paddingBottom:'1rem' }}>
          <span style={{ fontWeight: 'bold', color: '#3A337F' }}>
            Selected Category: {filtroCategoria}
          </span>
        </div>
      )}
      <Grid container spacing={2}>
        {currentCards.map((item, index) => (
          <Grid item key={item.id} xs={12} sm={6} md={4} lg={3}>
            {filtroCategoria === '' || item.categoria === filtroCategoria ? (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: index * 0.2,
                  ease: [0, 0.71, 0.2, 1.01]
                }}
                className='rounded-xl p-5 shadow-lg min-h-full'
                style={{ backgroundColor:'#A3E0D1' }}
              >
                <div>
                  <div className='flex md:justify-start py-2 justify-start'>
                    <img src={item.imagen} alt={item.titulo} className='max-h-16'/>
                  </div>
                  <h3 className='font-black text-xl'>{item.titulo}</h3>
                  <p className='text-lg'>{item.texto}</p>
                  <p className='font-bold ' style={{ color:'#1B695F' }}>{item.fecha}</p>
                  <p className='font-bold ' style={{ color:'#3A337F' }}>{item.categoria}</p>
                  <Link to={`/jobseekers/${item.id}`}>
                    <div className='py-4'>
                      <Button variant="contained" className='py-10' style={{ backgroundColor:'#635BB8', color:'#FAEEDD', fontWeight:'600', textTransform: 'none' }}>
                        Apply for this job
                      </Button>
                    </div>
                  </Link>
                </div>
              </motion.div>
            ) : null}
          </Grid>
        ))}
      </Grid>
      <div className="pagination flex justify-center pt-5">
        <button 
          onClick={() => paginate(currentPage - 1)} 
          disabled={currentPage === 1} 
          className=" font-bold py-2 px-4 rounded-l focus:outline-none focus:shadow-outline"
          style={{ backgroundColor: '#635BB8', color: '#FAEEDD' }}
        >
          Previous
        </button>
        {Array.from({ length: Math.ceil(filteredData.length / cardsPerPage) }).map((_, index) => (
          <button 
            key={index} 
            onClick={() => paginate(index + 1)} 
            className={`mx-1 px-3 py-2 rounded-md focus:outline-none font-bold ${currentPage === index + 1 ? 'bg-violet-500 text-white font-bold' : 'bg-violet-300 hover:bg-gray-400 text-gray-800'}`}
          >
            {index + 1}
          </button>
        ))}
        <button 
          onClick={() => paginate(currentPage + 1)} 
          disabled={currentPage === Math.ceil(filteredData.length / cardsPerPage)} 
          className="font-bold py-2 px-4 rounded-r focus:outline-none focus:shadow-outline"
          style={{ backgroundColor: '#635BB8', color: '#FAEEDD' }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TarjetasComponent;
