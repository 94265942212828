import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import Modal from "react-modal";
import Lottie from "react-lottie";
import animationData from "../assets/email-animation.json";
import { useDropzone } from "react-dropzone";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { firebaseConfig } from "../firebaseConfig";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "../App.css";

import loadingAnimationData from "../assets/loading2.json"; // Cambié el nombre del archivo de animación de carga


const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const storage = getStorage(app);

Modal.setAppElement("#root");

const Aplicationform = () => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    surname: "",
    cv: null,
    dateOfBirth: "",
    phone: "",
    country: "",
    city: "",
    linkedin: "",
    experience: "",
    english: "",
    message: "",
    termsCheckbox: true,
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Nuevo estado para controlar el estado de carga
  const handleDrop = (acceptedFiles) => {
    setFormData((prevData) => ({
      ...prevData,
      cv: acceptedFiles[0], // Tomamos solo el primer archivo si hay varios
    }));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: ".pdf, .doc, .docx, .jpg, .jpeg, .png",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const formRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validar campos obligatorios
    const requiredFields = ["name", "surname", "cv", "dateOfBirth", "phone", "country", "city", "linkedin", "experience", "english", "message"];
    const isEmptyField = requiredFields.some(field => !formData[field]);
    if (isEmptyField) {
      setError("Please fill in all required fields");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }

    try {
      // Validaciones
      setLoading(true);
      console.log("Form Data:", formData);
      // Subir archivo al Storage de Firebase
      const storageRef = ref(storage, `cv/${formData.cv.name}`);
      await uploadBytes(storageRef, formData.cv);

      // Obtener la URL de descarga del archivo
      const downloadURL = await getDownloadURL(storageRef);
      console.log("Download URL:", downloadURL);
      // Agregar la URL de descarga al objeto formData
      const formDataWithDownloadURL = {
        ...formData,
        cv: downloadURL,
      };

      // Enviar datos a Firebase Firestore
      console.log("Data to be saved:", formDataWithDownloadURL);
      const docRef = await addDoc(collection(db, "ubstaff"), formDataWithDownloadURL);

      console.log("Document written with ID: ", docRef.id);

      // Restablecer el formulario después de enviar los datos
      formRef.current.reset();

      // Mantener el valor actual de cv
      setFormData((prevData) => ({
        ...prevData,
        cv: prevData.cv,
      }));

      setTimeout(() => {
        setFormSubmitted(true);
        setIsModalOpen(true);
        setLoading(false); // Desactivar el estado de carga
      }, 2000); // Simulación de un proceso de 2 segundos (ajusta según sea necesario)
    } catch (error) {
      console.error("Error adding document: ", error.message);
      setErrorMessage("Error submitting the form. Please try again.");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormSubmitted(false);
    // Agrega la redirección a la página de inicio al cerrar el modal
    // Por ejemplo, usando react-router
    // Puedes ajustar según tu enrutador o preferencia.
    // Si no estás utilizando un enrutador, puedes usar window.location.href
    window.location.href = "/jobseeker"; // Cambia '/' por la ruta de tu página de inicio
  };

  return (
    <div className="flex flex-col items-center justify-center mt-8 py-10">
      <div className="flex flex-col items-center sm:flex-row">
        <div
          className={`mt-8 sm:ml-8 sm:mt-0 order-1 sm:order-2 ${
            formSubmitted || loading ? "hidden" : "" // Ocultar el formulario cuando está cargando o ya se ha enviado
          }`}
        >
          <p className="text-4xl font-black mb-4" style={{ color: "#301C49" }}>
            Apply now
          </p>
          <p className="font-bold text-xl mb-6" style={{ color: "#301C49" }}>
            for future jobseekers.
          </p>
          <div id="contact">
            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-base font-bold "
                    style={{ color: "#003A33" }}
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name_id"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Matias"
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight: "800", color: "#301C49" }}
                  />
                </div>

                <div>
                  <label
                    htmlFor="surname"
                    className="block text-base font-bold "
                    style={{ color: "#003A33" }}
                  >
                    Surname
                  </label>
                  <input
                    type="text"
                    id="surname_id"
                    name="surname"
                    value={formData.surname}
                    onChange={handleChange}
                    placeholder="Gonzalez"
                    className="mt-1 p-2 border w-full border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight: "800", color: "#301C49" }}
                  />
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="cv"
                    className="block text-base font-bold "
                    style={{ color: "#003A33" }}
                  >
                    CV (PDF/Word/Image)
                  </label>
                  <div
                    {...getRootProps()}
                    className={`mt-1 p-2 w-full border ${
                      isDragActive ? "border-green-500" : "border-violet-500"
                    } rounded-md cursor-pointer focus:outline-none focus:border-blue-500`}
                    style={{ fontWeight: "800", color: "#301C49" }}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here...</p>
                    ) : (
                      <p className="text-gray-400">
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    )}
                  </div>
                  {formData.cv && (
                    <p className="text-base mt-2" style={{ color: "#003A33" }}>
                      File selected: {formData.cv.name}
                    </p>
                  )}
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="dateOfBirth"
                    className="block text-base font-bold "
                    style={{ color: "#003A33" }}
                  >
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    id="dateOfBirth_id"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    placeholder="dd / mm / aaaa"
                    onChange={handleChange}
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight: "800", color: "#301C49" }}
                  />
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="phone"
                    className="block text-base font-bold "
                    style={{ color: "#003A33" }}
                  >
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone_id"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="+549112535564"
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight: "800", color: "#301C49" }}
                  />
                </div>

                <div className="">
                  <label
                    htmlFor="country"
                    className="block text-base font-bold "
                    style={{ color: "#003A33" }}
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    id="country_id"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    placeholder="Argentina"
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight: "800", color: "#301C49" }}
                  />
                </div>

                <div className="">
                  <label
                    htmlFor="city"
                    className="block text-base font-bold "
                    style={{ color: "#003A33" }}
                  >
                    City
                  </label>
                  <input
                    type="text"
                    id="city_id"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="Buenos Aires"
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight: "800", color: "#301C49" }}
                  />
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="linkedin"
                    className="block text-base font-bold "
                    style={{ color: "#003A33" }}
                  >
                    LinkedIn Profile
                  </label>
                  <input
                    type="text"
                    id="linkedin_id"
                    name="linkedin"
                    value={formData.linkedin}
                    onChange={handleChange}
                    placeholder="https://www.linkedin.com/company/u-b-staff"
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight: "800", color: "#301C49" }}
                  />
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="experience"
                    className="block text-base font-bold "
                    style={{ color: "#003A33" }}
                  >
                    Years of Working Experience
                  </label>
                  <select
                    id="experience_id"
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight: "800", color: "#301C49" }}
                  >
                    <option value="" disabled defaultValue="">
                      Select an option
                    </option>
                    <option value="1">Junior (0-2 years)</option>
                    <option value="2">Semi Senior (3-10 years)</option>
                    <option value="3">Senior (+10 years)</option>
                  </select>
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="english"
                    className="block text-base font-bold "
                    style={{ color: "#003A33" }}
                  >
                    What is your level of spoken English?
                  </label>
                  <select
                    id="english_id"
                    name="english"
                    value={formData.english}
                    onChange={handleChange}
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight: "800", color: "#301C49" }}
                  >
                    <option value="" disabled defaultValue="">
                      Select an option
                    </option>
                    <option value="1">Basic</option>
                    <option value="2">Intermediate</option>
                    <option value="3">Upper Intermediate</option>
                    <option value="4">Advanced</option>
                  </select>
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-base font-bold "
                    style={{ color: "#003A33" }}
                  >
                    Tell us something about yourself that is not on your CV!
                  </label>
                  <textarea
                    id="message_id"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="4"
                    placeholder="Type here..."
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight: "800", color: "#301C49" }}
                  ></textarea>
                </div>

                <div className="col-span-2">
                  <button
                    type="submit"
                    className="w-full text-white p-2 rounded-md hover:bg-violet-600 bg-violet-400 transition duration-300"
                    style={{ fontWeight: "800", color: "#301C49" }}
                  >
                    Apply now
                  </button>
                </div>

                <div className="col-span-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      id="termsCheckbox_id"
                      name="termsCheckbox"
                      checked={formData.termsCheckbox}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          termsCheckbox: e.target.checked,
                        })
                      }
                      className="form-checkbox h-5 w-5 text-violet-600"
                    />
                    <span className="ml-2" style={{ color: "#003A33" }}>
                      By checking this box, you agree to the{" "}
                      <a href="/terms" className="text-violet-600 underline">
                        terms and conditions
                      </a>{" "}
                      here.
                    </span>
                  </label>
                </div>
              </div>
              {error && (
                <div
                  className="bg-red-200 text-red-600 text-black p-2 rounded-xl py-5 text-center text-xl my-5"
                  style={{ fontWeight: "800" }}
                >
                  {error}
                </div>
                
              )}
            </form>
          </div>
        </div>
      </div>

      {/* Mostrar la animación de carga si está activa */}
      {loading && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loadingAnimationData,
            }}
          />
          </div>
        </div>
      )}
      {/* Modal */}
      <div>
        <motion.div
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Message Sent Modal"
          className={`modal ${formSubmitted ? "block" : "hidden"}`}
          overlayClassName={`modal-overlay ${
            formSubmitted ? "block" : "hidden"
          }`}
        >
          <div className="modal-conten bg-green-200 p-10 rounded-xl text-center flex flex-col items-center">
            <div style={{ width: "100px", height: "100px" }}>
              <Lottie options={{ loop: true, autoplay: true, animationData }} />
            </div>
            <h2 className="text-green-500 font-black font-2xl">
              Message Sent Successfully!
            </h2>
            <p className="text-gray-600 font-black font-2xl">
              Your message has been sent. Thank you!
            </p>
            <button
              onClick={closeModal}
              className="text-white p-2 px-5 rounded-md font-black mt-4 boton-contact-form hover:bg-violet-600 bg-violet-400"
              style={{}}
            >
              Close
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Aplicationform;
