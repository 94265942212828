import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../App.css';
import img from '../assets/banner-talent-cta.svg';

const CTA = () => {
  return (
    <motion.div
      className="relative rounded-[20px] overflow-hidden bg-cover bg-center h-64"
      style={{ backgroundImage: `url(${img})` }} 
      // style={{ backgroundColor: `#1C4840` }} 
    >
      <div className="absolute inset-0"></div>
      <div className="absolute inset-0 flex items-center justify-center md:px-20 px-5">
        <div className="text-center text-neutral-50">
          <h2 className="md:text-3xl text-xl font-black mb-2">Work remotely for companies in the <span className='' style={{ color:'#A3E0D1' }}> USA, Canada and Worldwide</span></h2>
          <p className="mb-5 md:text-xl text-base">
          We have a carefully curated database of over 100 remote jobs opportunities waiting for you
          </p>
          <Link to='/jobseeker' className="font-black text-sm md:text-xl py-2 px-4 rounded-full boton-cta" style={{ backgroundColor:'' }}>
          Search your Dream Job
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default CTA;
