import React from 'react';
import Logo from '../assets/logo-contrast.svg';
import Isologo from '../assets/ubisolight.svg';
import FacebookIcon from '../assets/facebook.svg';
import InstagramIcon from '../assets/instagram.svg';
import YoutubeIcon from '../assets/youtube.svg';
import TwitterIcon from '../assets/twitter.svg';
import LinkedinIcon from '../assets/linkedin.svg';
import WhatsappIcon from '../assets/whatsapp.svg';

import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="text-white py-8">
      <div className="container mx-auto flex md:flex-row flex-col justify-between items-center">
        {/* Sección derecha con el logo y los enlaces */}

        <div className="flex md:hidden space-x-4 w-1/2 justify-center md:justify-end pb-10 md:pt-0">
        <a href="https://facebook.com/ubstaff.us" target="_blank" rel="">
            <img src={FacebookIcon} alt="Facebook" className="h-6 w-6" />
          </a>
          <a href="https://instagram.com/ubstaff.us" target="_blank" rel="">
            <img src={InstagramIcon} alt="Instagram" className="h-6 w-6" />
          </a>
          {/* <a href="#" target="_blank" rel="">
            <img src={YoutubeIcon} alt="Youtube" className="h-6 w-6" />
          </a> */}
          <a href="https://twitter.com/ubstaffus" target="_blank" rel="">
            <img src={TwitterIcon} alt="Twitter" className="h-6 w-6" />
          </a>
          <a href="https://www.linkedin.com/company/u-b-staff/" target="_blank" rel="">
            <img src={LinkedinIcon} alt="Linkedin" className="h-6 w-6" />
          </a>
          {/* <a href="#" target="_blank" rel="">
            <img src={WhatsappIcon} alt="Whatsapp" className="h-6 w-6" />
          </a> */}
        </div>
        <div className="flex-col items-center space-x-4 w-1/2 justify-start">
          {/* Logo */}
          <Link to="./">
          <img src={Logo} alt="Logo" className="w-48 md:flex hidden" />
          </Link>
          {/* Enlaces */}
          <div className="text-base">
            <div className='flex md:flex-row flex-col text-center md:text-left'>
            <p className="mb-2 font-semibold pr-4">
            <Link to="./terms">Terms & Conditions </Link>
              </p>
            <p className="mb-2 font-semibold">
              <Link to="./privacy">Privacy Policy </Link>
              </p>
            </div>
            <div className='flex md:flex-row flex-col md:text-left text-center'>
            <p className='font-bold'>© Copyright 2023 U&B STAFF | All rights reserved <br /> Zelten LLC</p>
            </div>
          </div>
        </div>

        {/* Sección izquierda con iconos de redes sociales */}
        <div className="md:flex hidden space-x-4 w-1/2 justify-center md:justify-end pt-10 md:pt-0">
          <a href="https://facebook.com/ubstaff.us" target="_blank" rel="">
            <img src={FacebookIcon} alt="Facebook" className="h-6 w-6" />
          </a>
          <a href="https://instagram.com/ubstaff.us" target="_blank" rel="">
            <img src={InstagramIcon} alt="Instagram" className="h-6 w-6" />
          </a>
          {/* <a href="#" target="_blank" rel="">
            <img src={YoutubeIcon} alt="Youtube" className="h-6 w-6" />
          </a> */}
          <a href="https://twitter.com/ubstaffus" target="_blank" rel="">
            <img src={TwitterIcon} alt="Twitter" className="h-6 w-6" />
          </a>
          <a href="https://www.linkedin.com/company/u-b-staff/" target="_blank" rel="">
            <img src={LinkedinIcon} alt="Linkedin" className="h-6 w-6" />
          </a>
          {/* <a href="#" target="_blank" rel="">
            <img src={WhatsappIcon} alt="Whatsapp" className="h-6 w-6" />
          </a> */}
        </div>

        <img src={Isologo} alt="Logo" className="w-10 md:hidden flex pt-10" />
      </div>
    </footer>
  );
};

export default Footer;
