import React from 'react';
import { motion } from 'framer-motion';
import img1 from '../assets/ladypc.png';


const Applytext = () => {
  return (
    <div className="flex flex-col md:flex-row align-middle items-center">
      {/* Columna izquierda con texto */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
        className="flex-1 px-10 pb-10">
        <h1 style={{ color:'#301C49' }} className="font-black text-4xl pt-20 md:pt-0 mb-4 text-left">We help you choose your dream job</h1>
        <p className="text-2xl font-semibold text-gray-900 mb-2 text-left">
        From the endless possibilities the world has to offer.
        </p>
        <p className="text-xl font-semibold text-gray-900 text-left">
        Work remotely for companies in the USA
        </p>
        <p className="text-xl font-semibold text-gray-900 text-left">
        Career growth and personal development
        </p>
        <p className="text-xl font-semibold text-gray-900 text-left">
        Become a part of a global network of top-tier professionals
        </p>
        <p className="text-xl font-semibold text-gray-900 text-left">
        Competitive compensation
        </p>
      </motion.div>

      {/* Columna derecha con la imagen */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
        className="flex-1 px-10">
        <img src={img1} alt="Card" className="w-full h-auto rounded-2xl" />
      </motion.div>
    </div>

    
  );
};

export default Applytext;
