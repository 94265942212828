// firebaseConfig.js
export const firebaseConfig = {
    apiKey: "AIzaSyD0a1innZJ4lxhciCLe_f15tXK0BqMOQoE",
    authDomain: "ubstaff-24f08.firebaseapp.com",
    projectId: "ubstaff-24f08",
    storageBucket: "ubstaff-24f08.appspot.com",
    messagingSenderId: "544224946019",
    appId: "1:544224946019:web:c3bbb2bbf69c1976719556",
    measurementId: "G-T45RW39G9V"
  };
  