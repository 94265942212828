import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import '../App.css'

const AccordionItem = ({ id, title, content, isOpen, toggleAccordion }) => {
  return (
    <div className="mb-1 md:px-0 px-10">
      <motion.div
        className="border rounded-md overflow-hidden relative"
        initial={false}
        animate={{ borderRadius: isOpen ? 0 : '1rem' }}
      >
        <button className="w-full p-3 text-left bg-white hovertext relative" onClick={() => toggleAccordion(id)}>
          {title}
          <span className={`absolute right-4 top-1/2 transform -translate-y-1/2 ${isOpen ? 'rotate-180' : ''}`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
              className="h-4 w-4">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                d="M19 9l-7 7-7-7"></path>
            </svg>
          </span>
        </button>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              key="content"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="p-4 bg-white"
            >
              {content}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

const Faq = () => {
  const [openItem, setOpenItem] = useState(null);

  const toggleAccordion = (id) => {
    setOpenItem((prevOpenItem) => (prevOpenItem === id ? null : id));
  };

  const faqData = [
    {
      id: 1,
      title: "Remote jobs for which locations?",
      content: "We specialize in connecting talented individuals with remote job opportunities in the USA, Canada, and worldwide."
    },
    {
      id: 2,
      title: "What types of jobs can they secure?",
      content: "We cover all kinds of jobs related to the IT world. We'll help you find the right job based on your skills and experience in the industry."
    },
    {
      id: 3,
      title: "Which countries or regions do you serve?",
      content: "We operate globally, facilitating remote job placements in the USA, Canada, and beyond."
    },
    {
      id: 4,
      title: "Are the job positions legitimate?",
      content: "We thoroughly vet and verify each remote job opportunity to ensure it meets our standards."
    },
    {
      id: 5,
      title: "Do you assist with visas and permits?",
      content: "Yes, we offer guidance and assistance with visa and work permit applications."
    },
    {
      id: 6,
      title: "What support do you offer?",
      content: "We provide personalized support, including resume/CV optimization and interview preparation."
    },
    {
      id: 7,
      title: "Are there fees for your services?",
      content: "Our services are typically free for job seekers, with any fees transparently communicated."
    },
    {
      id: 8,
      title: "What sets us apart",
      content: "Our extensive network and commitment to your success make us the ideal partner for remote job seekers."
    }
  ];

  return (
    <div className="">
        <h2 style={{ color:'#301C49' }} className='text-4xl text-center font-bold pt-10'>Frequently Asked Questions</h2>
      <div className='pt-10 max-w-2xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-4'>
        {faqData.map((item) => (
          <div key={item.id} className='font-bold text-lg' >
            <AccordionItem
              id={item.id}
              title={item.title}
              content={item.content}
              isOpen={openItem === item.id}
              toggleAccordion={toggleAccordion}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
