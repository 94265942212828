import React from 'react';
import Busquedas from '../components/Busquedas'
import { Container } from '@mui/material';

const jobseeker = () => {
  return (
    <Container>
      <section>
        <Busquedas/>
      </section>
    </Container>    
  );
};

export default jobseeker;
