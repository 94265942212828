// Privacy.js
import React from 'react';

const Privacy = () => {
  return (
    <div className="container mx-auto mt-8 text-gray-900 md:px-44 px-8 py-5">
      <h1 className="text-2xl font-black mb-4">U&B STAFF PRIVACY STATEMENT</h1>
      
      <p className="text-base font-semibold mb-4">
        U&B Staff is deeply committed to safeguarding your personal data. We prioritize ensuring that you feel secure when engaging with us, knowing that your personal information is in capable hands.
        <br /><br />
        We employ robust technical and organizational measures to protect your personal data from unauthorized or unlawful processing, accidental loss, alteration, disclosure, access, or unlawful destruction or damage.
        <br /><br />
        Our use of the information collected is geared towards providing you with the best and most personalized experience, including tailored marketing campaigns. This information is automatically processed by our systems, and in certain cases, we also utilize manual review to assess the collected data.
      </p>

      <div className="mb-4">
        <strong>Key Objectives:</strong>
        <br />
        <ul>
          <li>
            <strong>1. Promoting Safety, Security, and Integrity:</strong> We utilize the information collected to provide safe and secure services.
          </li>
          <li>
            <strong>2. Measuring and Analyzing Our Audience:</strong> Our goal is to assist our clients in enhancing business efficiency by reducing labor costs, necessitating the processing and analysis of your data.
          </li>
          <li>
            <strong>3. Communication:</strong> We communicate with you using the information provided, such as the contact details in your profile.
          </li>
          <li>
            <strong>4. Understanding Customer Needs and Service Improvement:</strong> We strive to simplify our clients' lives by offering quality talents and continuous support.
          </li>
        </ul>
      </div>

      <p className="mb-4">
        We retain information only for the duration necessary to provide our services, comply with legal obligations, or protect our interests. The determination of how long we retain information is made on a case-by-case basis.
      </p>

      <p className="mb-4">
        <strong>Your Rights Regarding Personal Data Processing:</strong>
        <br /><br />
        In certain circumstances and subject to applicable law, you have the right to:
        <br /><br />
        <ol>
          <li>Request access to the personal data we process about you, including information about whether we hold personal data and obtaining a copy of that data.</li>
          <li>Request rectification of your personal data if it is inaccurate or incomplete.</li>
          <li>Object to the processing of your personal data, requesting that U&B Staff cease processing it.</li>
          <li>Request the erasure of your personal data.</li>
          <li>Request the restriction of the processing of your personal data, allowing limited processing under specific circumstances, including with your consent.</li>
        </ol>
      </p>

      <p className="mb-4">
        The usage and processing of your personal data may be modified based on the context, and any changes will be reflected in this policy.
      </p>

      <p className="mb-4">
        These privacy policies will be periodically updated without prior notice. U&B Staff is not obligated to provide advance notice of updates. In the event that legal requirements necessitate the disclosure of information in our records, U&B Staff will comply accordingly.
      </p>
    </div>
  );
};

export default Privacy;
