// aboutus.js
import React from "react";
import Displaytext from "../components/Displaytext";
import Banner from "../components/Banner-about";
import Values from "../components/Values";
import { Container } from "@mui/material";

const aboutus = () => {
  return (
    <div>
      <Container>
        <section>
          <Displaytext />
        </section>
      </Container>
      <Banner />
      <Container>
        <section className="pt-10">
          <Values />
        </section>
      </Container>
    </div>
  );
};

export default aboutus;
